<template>
  <div id="home">
    <Header />
    <div id="home-spacer-1"></div>
    <Unternehmen />
    <div id="leistung-home"></div>
    <Leistung />
    <LeistungMobile />
    <Kontakt />
  </div>
</template>

<script>
  import Header from '../components/Header'
  import Unternehmen from '../components/Unternehmen'
  import Leistung from '../components/Leistung'
  import LeistungMobile from '../components/LeistungMobile'
  import Kontakt from '../components/Kontakt'

  export default {
    name: 'Home',

    components: {
      Header,
      Unternehmen,
      Leistung,
      LeistungMobile,
      Kontakt
    },
  }
</script>

<style>
  #home-spacer-1 {
    height: 100px;
  }


	@media only screen and (max-width: 1281px) {
    #leistung-mobil{
      display: block;
    }
    #leistung{
      display: none;
    }
  }
  @media only screen and (min-width: 1282px) {
    #leistung-mobil{
      display: none;
    }
  }
</style>