<template>
  <div id="leistung">
    <h2 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="400">
      Leistungen
    </h2>
    <hr class="heading-hr" />
    <div id="leistung-content">
      <div class="row" id="leistung-1">
        <div class="col-8">
          <div class="vl-leistung" id="vl-leistung-1"></div>
          <h3 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="1200">
            Neubau oder Umbau Ihres Projektes
          </h3>
          <hr id="leistung-hr-1" class="leistung-hr" />
          <p data-aos="fade-in" data-aos-duration="1200" data-aos-delay="1500">
            Sie brauchen eine Veränderung rund um Ihr Eigenheim? Von der
            Herstellung eines neuen Pflasterbelags über die Veränderung diverser
            Sichtschutzgestaltungen oder doch die Herstellung eines
            Alleinstellungsmerkmales durch gewisse „Eye-Catcher“? Wir helfen
            Ihnen gerne.
          </p>
        </div>
        <div class="col-4">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            fade
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_1.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_2.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_4.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_3.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_5.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_6.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_7.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_8.jpg"
                  alt="image slot"
                />
              </template>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild3_9.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
      <div class="row" id="leistung-2">
        <div class="col-4">
          <b-carousel
            id="carousel-2"
            v-model="slide2"
            :interval="40000"
            controls
            fade
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild4_1.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild4_2.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild4_4.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild4_5.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild4_6.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div class="col-8">
          <div class="vl-leistung" id="vl-2"></div>
          <h3 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="2000">
            Sichtschutzgestaltungen
          </h3>
          <hr id="leistung-hr-2" class="leistung-hr" />
          <p data-aos="fade-in" data-aos-duration="1200" data-aos-delay="2000">
            Sie wollen ungestört auf Ihrer Terrasse oder Ihrem Garten die Zeit
            mit Ihren Liebsten verbringen? In der heutigen Zeit der
            Materialvielfalt finden wir die passende Lösung die all Ihren
            Vorlieben und Ansprüchen einer Sichtschutzgestaltung entspricht.
          </p>
        </div>
      </div>
      <div class="row" id="leistung-3">
        <div class="col-8">
          <div class="pt-leistung" id="pt-leistung-3"></div>
          <h3 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="1200">
            Natursteinarbeiten
          </h3>
          <hr id="leistung-hr-3" class="leistung-hr" />
          <p data-aos="fade-in" data-aos-duration="1200" data-aos-delay="1500">
            Wohlfühlambiente durch Sandsteine im Tosacana-Flair. Robustes
            Auftreten durch Granit oder Basalt. Dank der Vielfalt und der
            Individualität der Natursteine können wir Ihrem Zuhause die nötige
            Wertigkeit, Beständigkeit und Unvergänglichkeit verleihen.
          </p>
        </div>
        <div class="col-4">
          <b-carousel
            id="carousel-3"
            v-model="slide3"
            :interval="4000"
            controls
            fade
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild7_2.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild7_3.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild7_4.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild7_5.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
      <div class="row" id="leistung-4">
        <div class="col-4">
          <b-carousel
            id="carousel-4"
            v-model="slide4"
            :interval="40000"
            controls
            fade
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild7_1.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_1.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_2.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_3.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_4.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_5.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_6.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_7.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_8.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild5_9.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div class="col-8">
          <div class="vl-leistung" id="vl-4"></div>
          <h3 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="2000">
            Terassen - und Gartengestaltung, Pflaster und Plattenbeläge,
            Rollrasen
          </h3>
          <hr id="leistung-hr-4" class="leistung-hr" />
          <p data-aos="fade-in" data-aos-duration="1200" data-aos-delay="2000">
            Ihre Terrasse, der Garten und der gepflegte Rollrasen repräsentiert
            zum einen nicht nur Ihre Immobilie nach Außen, sondern ist Ihre
            heimische Wohlfühloase in der Sie ungestört und zu jeder Zeit Ihrem
            Hobby nachgehen können. Über die Pflege Ihrer Pflanzen, das
            Einrichten einer Outdoorküche, eines Pools oder eines eigenen
            Fitnessstudios: Es sind keine Grenzen gesetzt!
          </p>
        </div>
      </div>
      <div class="row" id="leistung-5">
        <div class="col-8">
          <div class="pt-leistung" id="pt-leistung-5"></div>
          <h3 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="1200">
            Bewässerungs- / Entwässerungssysteme
          </h3>
          <hr id="leistung-hr-5" class="leistung-hr" />
          <p data-aos="fade-in" data-aos-duration="1200" data-aos-delay="1500">
            Sie wollen auch im Urlaub jederzeit Ihrem Garten die nötige Pflege
            zusichern und das entweder automatisiert oder vom Handy aus? Durch
            modernste Bewässerungssysteme steht diesem Anwendungsbereich nichts
            im Wege.<br />
            Würden Sie sich gerne nach Ihrer Sporteinheit, einem Saunagang in
            Ihrer Outdoorsauna oder einem anstrengenden Tag in Ihrer Außendusche
            beim Sonnenuntergang frisch machen? Wir bieten die passende Lösung.
          </p>
        </div>
        <div class="col-4">
          <b-carousel
            id="carousel-5"
            v-model="slide5"
            :interval="4000"
            controls
            fade
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild6_1.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild6_2.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild6_3.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  loading="lazy"
                  src="@/assets/Bilder/Leistungen/Bild6_4.jpg"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
      <!-- <div class="row" id="leistung-6">
				<div class="col-4">
					<b-carousel id="carousel-6" v-model="slide" :interval="40000" controls fade indicators
						background="#ababab" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;"
						@sliding-start="onSlideStart" @sliding-end="onSlideEnd">
						<b-carousel-slide>
							<template #img>
								<img class="d-block img-fluid w-100" loading="lazy" src="@/assets/Bilder/Leistungen/Bild3_1.jpg"
									alt="image slot">
							</template>
						</b-carousel-slide>
					</b-carousel>
				</div>
				<div class="col-8">
					<div class="vl-leistung" id="vl-6"></div>
					<h3 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="2000">
						Natursteinarbeiten
					</h3>
					<hr id="leistung-hr-6" class="leistung-hr">
					<p data-aos="fade-in" data-aos-duration="1200" data-aos-delay="2000">
						Wohlfühlambiente durch Sandsteine im Tosacana-Flair. Robustes Auftreten durch Granit oder
						Basalt. Dank der Vielfalt und der Individualität der Natursteine können wir Ihrem Zuhause die
						nötige Wertigkeit, Beständigkeit und Unvergänglichkeit verleihen.
					</p>
				</div>
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  mounted() {
    this.loadVL();
    this.loadHR();
  },
  methods: {
    loadVL() {
      const startAnimation = (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.target.classList.contains("linear-wipe-down")) {
            entry.target.classList.toggle(
              "linear-wipe-down",
              entry.isIntersecting
            );
          }
        });
      };

      const observer = new IntersectionObserver(startAnimation);
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      };

      const elements = document.querySelectorAll(".vl-leistung");
      elements.forEach((el) => {
        observer.observe(el, options);
      });
    },
    loadHR() {
      const startAnimation = (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.target.classList.contains("linear-wipe-1")) {
            entry.target.classList.toggle(
              "linear-wipe-1",
              entry.isIntersecting
            );
          }
        });
      };

      const observer = new IntersectionObserver(startAnimation);
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      };

      const elements = document.querySelectorAll(".leistung-hr");
      elements.forEach((el) => {
        observer.observe(el, options);
      });
    },
  },
};
</script>

<style>
#leistung {
  padding-top: 100px;
  text-align: left;
  padding-bottom: 100px;
  background-color: white;
}

#leistung .carousel .sr-only {
  display: none;
}

#leistung .carousel {
  border-radius: 25px;
  border: 5px solid var(--black);
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.5);
}

#leistung h2 {
  color: black;
  text-align: left;
  font-family: "MetropolisRegular";
  font-weight: bolder !important;
  font-size: 30px !important;
}

#leistung p {
  color: black;
  text-align: left;
  font-family: "MetropolisRegular";
  font-weight: 400;
  font-size: 20px !important;
}

#leistung h3 {
  color: black;
  font-family: "MetropolisRegular";
  font-weight: bolder !important;
  font-size: 20px !important;
}

#leistung img {
  border-radius: 20px;
}

#leistung #leistung-content,
#leistung h2 {
  padding-left: 10vw;
  padding-right: 10vw;
}

#leistung-content {
  margin-top: -30px;
}

.carousel-item {
  background-color: transparent !important;
}

.linear-wipe-1 {
  overflow: hidden;
  animation: linearwipe-1 0.75s steps(120, end);
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1.5s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes linearwipe-1 {
  from {
    width: 0;
  }

  100% {
    width: 110%;
  }
}

@keyframes linearwipedownLeistung {
  from {
    height: 0;
    display: none;
  }

  100% {
    height: 15vw;
    display: block !important;
  }
}

#leistung #leistung-2,
#leistung #leistung-4,
#leistung #leistung-6 {
  margin-top: -20px;
}

#leistung #vl-leistung-1 {
  border-left: 4px solid black;
  height: 0vw;
  border-radius: 0px 0px 7px 7px;
  width: 4px;
  position: relative;
  left: 0;
  margin-top: -100px;
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
}

#leistung #pt-leistung-3,
#leistung #pt-leistung-5 {
  margin-top: 7.5vw;
  height: 20px;
  width: 20px;
  position: relative;
  top: 9px;
  left: -0.5vw;
  background-color: black;
  border-radius: 50%;
}

.linear-wipe-down {
  white-space: nowrap;
  overflow: hidden;
  animation: linearwipedownLeistung 0.5s steps(120, end);
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
}

#leistung #vl-2,
#leistung #vl-4,
#leistung #vl-6 {
  border-left: 4px solid black;
  height: 0vw;
  border-radius: 0px 0px 7px 7px;
  width: 4px;
  position: relative;
  margin-left: auto;
  margin-right: 13vw;
  margin-top: -120px;
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
}

#leistung #leistung-1 h3,
#leistung #leistung-3 h3,
#leistung #leistung-5 h3 {
  padding-left: 1vw;
  padding-right: 1vw;
  margin-top: -30px;
}

#leistung #leistung-2 h3,
#leistung #leistung-4 h3,
#leistung #leistung-6 h3 {
  padding-right: 14vw;
  text-align: right;
  margin-top: -30px;
}

#leistung #leistung-hr-1,
#leistung #leistung-hr-3,
#leistung #leistung-hr-5 {
  width: 0vw;
  opacity: 1;
  margin-top: -5px;
  background-color: #000000;
  border: 2px solid black;
  border-radius: 7px 7px 7px 7px;
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
}

#leistung-hr-2,
#leistung-hr-4,
#leistung-hr-6 {
  width: 0vw;
  opacity: 1;
  margin-top: -5px;
  background-color: #000000;
  float: right;
  margin-right: 13vw;
  border: 2px solid black;
  border-radius: 7px 7px 7px 7px;
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
}

#leistung #leistung-1 p,
#leistung #leistung-3 p,
#leistung #leistung-5 p {
  padding-left: 20px;
  padding-top: 20px;
  max-width: 90%;
}

#leistung #leistung-2 p,
#leistung #leistung-4 p,
#leistung #leistung-6 p {
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 14vw;
}

#leistung #carousel-1,
#leistung #carousel-3,
#leistung #carousel-5 {
  transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg)
    rotate(2deg);
  z-index: 4;
}

#leistung #carousel-2,
#leistung #carousel-4,
#leistung #carousel-6 {
  transform: scale(1) perspective(1040px) rotateY(11deg) rotateX(2deg)
    rotate(-2deg);
  margin-left: -20px;
}

#leistung #leistung-content .row {
  padding-top: 100px;
}

@media only screen and (max-width: 1612px) {
  #leistung #leistung-4 h3 {
    margin-top: -55px;
  }
}

.carousel-inner {
  background-color: var(--black);
  border-radius: 20px;
}

#leistung img {
  width: 100%;
  height: 30vw;
}
</style>
