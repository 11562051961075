<template>
  <div id="footer">
    <div class="main-footer">
      <img
        src="@/assets/CHCLogo.png"
        class="navbar-img"
        href="https://calvinherbconstruction.de/#app"
      />
      <b-row>
        <b-col md="3">
          <h3>Kontakt</h3>
          <ul>
            <li>Calvin Herb Construction UG (Haftungsbeschränkt)</li>
            <li>Schubertstraße 13</li>
            <li>75334 Straubenhardt</li>
          </ul>
          <ul>
            <li><b-icon icon="telephone-fill"></b-icon> 01749739353</li>
            <li>
              <a
                href="mailto:info@calvinherbconstruction.de"
                style="white-space: nowrap"
              >
                <b-icon icon="at"></b-icon> info@calvinherbconstruction.de
              </a>
            </li>
          </ul>
        </b-col>
        <b-col md="3">
          <h3>Menü</h3>
          <ul>
            <li>
              <a href="https://calvinherbconstruction.de/#app">Start</a>
            </li>
            <li>
              <a href="https://calvinherbconstruction.de/#unternehmen"
                >Unternehmen</a
              >
            </li>
            <li>
              <a href="https://calvinherbconstruction.de/#leistung-home"
                >Leistungen</a
              >
            </li>
            <li>
              <a href="https://calvinherbconstruction.de/#kontakt">Kontakt</a>
            </li>
          </ul>
        </b-col>
        <b-col md="3">
          <h3>Rechtliches</h3>
          <ul>
            <li>
              <router-link :to="'/datenschutz'" replace
                >Datenschutz</router-link
              >
            </li>
            <li>
              <router-link :to="'/impressum'" replace>Impressum</router-link>
            </li>
          </ul>
        </b-col>
        <b-col md="3">
          <h3>Social Media</h3>
          <a
            href="https://www.instagram.com/calvinherbconstruction/"
            target="_blank"
          >
            <b-icon icon="instagram"></b-icon> calvinherbconstruction
          </a>
        </b-col>
      </b-row>
    </div>
    <div class="sub-footer" id="sub-footer">
      <div class="row">
        <div class="col-6" id="left-col">
          <a href="#app">© {{ new Date().getFullYear() }} cHc</a>
        </div>
        <div class="col-6" id="right-col">
          <a href="https://zollerdesign.de" style="white-space: nowrap"
            >ZollerDesign, made with <b-icon icon="heart-fill"></b-icon
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style>
.main-footer {
  background-color: var(--black);
  padding-left: 10vw;
  padding-right: 10vw;
  text-align: left;
}

.main-footer .row {
  margin-top: 10px;
}
#footer {
  z-index: 4;
  position: relative;
}

#footer h3 {
  color: #707070;
  font-size: 20px;
  text-transform: uppercase;
}

#footer ul {
  list-style-type: none;
  padding-left: 0;
  color: #707070;
  font-size: 17.5px;
}

#footer a {
  color: #707070;
  font-size: 17.5px;
  text-decoration: none;
  transition: 1s;
}

#footer a:hover {
  color: var(--yellow);
}

.sub-footer {
  padding-left: 10vw;
  padding-right: 10vw;
  background-color: #2c2c2c;
  padding-bottom: 20px;
}

.sub-footer a {
  font-size: 15px !important;
}

.sub-footer #left-col {
  text-align: left;
}

.sub-footer #right-col {
  text-align: right;
}

@media only screen and (max-width: 960px) {
  #footer {
    text-align: center;
    padding-top: 25px;
  }

  .main-footer {
    text-align: center;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 960px) {
  #footer #right-col {
    margin-left: -10px;
  }
}

#sub-footer::after {
}
</style>
