<template>
  <div id="app">
    <div id="website">
      <img id="header-img" src="@/assets/BG/HeaderBG1.jpeg">
      </div>
      <b-navbar toggleable="lg" id="nav">
        <b-navbar-brand href="https://calvinherbconstruction.de/">
          <img src="@/assets/CHCLogo.png" class="navbar-img">
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <p style="color: yellow">Menü</p>
            <b-icon v-if="expanded" icon="bricks" style="color: yellow;"></b-icon>
            <b-icon v-else icon="bricks" style="color: yellow"></b-icon>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item class="b-nav-item active" href="https://calvinherbconstruction.de/#unternehmen" active>
              Unternehmen</b-nav-item>
            <b-nav-item href="https://calvinherbconstruction.de/#leistung-home">Leistungen</b-nav-item>
            <b-nav-item href="https://calvinherbconstruction.de/#kontakt">Kontakt</b-nav-item>
            <b-nav-item href="https://www.instagram.com/calvinherbconstruction/" target="_blank">
              <b-icon icon="instagram"></b-icon>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <router-view />
      <Footer />
    </div>    
  </div>
</template>

<script>
  import Footer from '@/components/Footer'

  import $ from "jquery";
  export default {
    components: {
      Footer,
    },
    mounted() {
      $(document).ready(function () {
        if (window.location.hash) {
          var hash = window.location.hash;
          $('html, body').animate({
            scrollTop: $(hash).offset().top
          }, 500);
        };
      });
      $(window).on('scroll', function () {
        var pixs = $(document).scrollTop()
        pixs = pixs / 100;
        $("#header-img").css({
          "-webkit-filter": "blur(" + pixs + "px)",
          "filter": "blur(" + pixs + "px)"
        })
      });
      const startAnimation = (entries, observer) => {
        entries.forEach(entry => {
          entry.target.classList.toggle("linear-wipe", entry.isIntersecting);
        });
      };

      const observer = new IntersectionObserver(startAnimation);
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1
      };

      const elements = document.querySelectorAll('.heading-hr');
      elements.forEach(el => {
        observer.observe(el, options);
      });

     
    },
    methods: {
      
    }
  }
</script>

<style>
  #app {
    font-family: 'MetropolisRegular';
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background-color: var(--black);
    overflow: hidden;
  }

  #header-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 0;
    overflow: hidden;
    
  }




  :root {
    --yellow: #FFFD00;
    --black: #1A1A1A;
  }

  ::selection {
    background: var(--yellow);
    /* WebKit/Blink Browsers */
  }

  #nav {
    padding-left: 10vw;
    padding-right: 10vw;

  }

  #nav a {
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    transition: 1s;
  }

  #nav a:hover {
    color: var(--yellow);
  }

  #nav li {
    margin-left: 20px;
  }

 

  #nav-collapse {
    justify-content: flex-end;
  }

  .navbar-img {
    width: 300px;
  }



  .navbar-light .navbar-toggler {
    color: yellow !important;
  }

  .collapsing {
    background-color: var(--black);
    margin-left: -100px;
    margin-right: -100px;
    margin-top: 25px;
    text-align: right;
    padding-right: 10vw;
  }

  /* .show {
    background-color: var(--black);
    margin-left: -100px;
    margin-right: -100px;
    margin-top: 25px;
    transition: 1s;
    text-align: right;
    padding-right: 15vw;
  } */

  .heading-hr {
    width: 0vw;
    opacity: 1;
    background-color: #000000;
    border: 2px solid black;
    border-radius: 7px 7px 7px 7px;
    -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
    box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
    white-space: nowrap;
  }

  .linear-wipe {
    overflow: hidden;
    animation: linearwipe 0.75s steps(120, end);
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
  }

  @keyframes linearwipe {
    from {
      width: 0;
    }

    100% {
      width: 35vw;
    }
  }

  @media only screen and (max-width: 900px) {
    @keyframes linearwipe {
      from {
        width: 0;
      }

      100% {
        width: 45vw;
      }
    }
  }

  @media only screen and (max-width: 550px) {
    @keyframes linearwipe {
      from {
        width: 0;
      }

      100% {
        width: 300px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    #header-img {
      object-fit: cover;
      height: 150vh;
    }

    .overlay {
      height: 180vh;
    }
  }

  @media only screen and (max-width: 800px) {
    .navbar-img {
      width: 200px !important;
    }
  }

  @media only screen and (max-width:500px) {
    #header-img {
      object-fit: cover;
      height: 150vh;
    }

    .overlay {
      height: 150vh;
    }
  }
</style>