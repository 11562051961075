import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Datenschutz from '../views/Datenschutz.vue'
import Impressum from '../views/Impressum.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  },
  {
    path: '/Impressum',
    name: 'Impressum',
    component: Impressum
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   }
  // }
]


let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,

})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.fullPath.indexOf("impressum") != -1) {
    // document.getElementById("home-overlay").style.height = "111vh"
     document.getElementById("header-img").style.maxHeight = "110vh"
    
    next()
    return
  } else {
 
    next()
  }
  
})

// if (window.location.href.indexOf("impressum") != -1){
//   document.getElementById("home-overlay").style.height = "100px"
// }
export default router