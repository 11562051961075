import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vuetify from './plugins/vuetify'

import config from "./../vue.config.js";
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueRellax from 'vue-rellax'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueRellax)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  data:{
    config
  },
  mounted() {
    AOS.init()
  },
  render: function (h) { return h(App) }
}).$mount('#app')

