<template>
  <div id="datenschutz">
    <h1>
      Haftungsausschluss, Rechtliche Hinweise, Datenschutz
    </h1>
    <h2>
      Inhaltliche Verantwortung und Haftungsbeschränkung
    </h2>
    <p>
      Das Angebot von Clavin Herb Construction besteht zum Teil aus redaktionellen Inhalten. Diese Inhalte wurden mit
      größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit
      und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des
      Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung
      des
      Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem
      Nutzer und dem Anbieter zustande.

      Bezüglich der redaktionellen Inhalte, die uns Partner zur Verfügung stellen und bezüglich von Interessierten
      präsentierten Informationen, Produkten und Dienstleistungen wird keine Gewähr für die Richtigkeit übernommen und
      jede Haftung ausgeschlossen.
    </p>
    <!-- <h2>
      Externe Links
    </h2>
    <p>
      Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der Haftung
      der jeweiligen Betreiber. Fitness Forum in Neuenbürg hat bei der erstmaligen Verknüpfung der externen Links die
      fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine
      Rechtsverstöße
      ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte
      der
      verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis
      oder
      Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne
      konkrete
      Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links
      unverzüglich gelöscht.
    </p> -->
    <h2>
      Urheber- und Leistungsschutzrechte
    </h2>
    <p>
      Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede
      vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen
      Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung,
      Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen
      Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
      Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar.
      Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch
      ist erlaubt. Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
    </p>
    <h2>
      Bildlizenzen
    </h2>
    <p>
      Die Bildrechte liegen beim Betreiber dieser Website. Jegliche Verwendung von Bildern der Internetseiten ist ohne
      Erlaubnis nicht gestattet. Ebenso nicht gestattet ist die Veränderung oder Weiterverarbeitung der Bilder dieser
      Website.
    </p>
    <h2>
      Datenschutz Informationen
    </h2>
    <p>
      Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (E-Mail
      Adressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich
      freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich und
      zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet.

      Bei jedem Zugriff eines Nutzers auf eine Seite aus dem Angebot und bei jedem Abruf
      einer Datei werden Daten über diesen Vorgang in einer Protokolldatei gespeichert.

      Im einzelnen wird über jeden Abruf folgender Datensatz gespeichert:

      Name der abgerufenen Datei,
      Datum und Uhrzeit des Abrufs,
      übertragene Datenmenge,
      Referrer und Herkunft des Benutzers
      Meldung, ob der Abruf erfolgreich war.
      Es wird ein Cookie gesetzt um wiederkehrende Besucher zu erkennen.
      Zusätzlich wird die IP-Adresse des Rechners, von dem die Anfrage abgeschickt wurde, gespeichert.

      Die gespeicherten Daten werden zu statistischen Zwecken der Webanalyse und des Webcontrollings ausgewertet und zur
      kontinuierlichen Verbesserung der Webseiten und des Marketing und des Vertriebs 
      verwendet. Eine Weitergabe an Dritte findet nicht statt außer im Falle von Google Analytics siehe folgender Zusatz
      Absatz.

      Diese Website benutzt zusätzlich Google Analytics, einen Webanalysedienst der Google Inc. ("Google") Google
      Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse
      der
      Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung
      dieser
      Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort
      gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
      die
      Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und
      der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls
      an
      Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
      verarbeiten. Google wird nach Aussagen von Google in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in
      Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser
      Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
      Funktionen dieser Website voll umfänglich nutzen können.

      Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
      Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie
      das
      unter dem folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser-Plugin herunterladen
      und
      installieren.

      Nähere Informationen hierzu finden Sie unter http://tools.google.com/dlpage/gaoptout?hl=de bzw. unter
      http://www.google.de/analytics/terms/de.html (allgemeine Informationen zu Google Analytics und Datenschutz). Wir
      weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um den Code "gat._anonymizeIp();" erweitert
      wurde,
      um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.

      Sie können die Erfassung durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein
      Opt-Out-Cookie gesetzt, der die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert: Google
      Analytics deaktivieren

      Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch uns und
      durch Google sowie in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
    </p>
    <h2>
      Besondere Nutzungsbedingungen
    </h2>
    <p>
      Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Bedingungen abweichen,
      wird
      an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die
      besonderen Nutzungsbedingungen.
    </p>
    <h2>
      Gewerbliche Werbung
    </h2>
    <p>
      Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es sei
      denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt oder es besteht bereits eine
      Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder
      kommerziellen Verwendung und Weitergabe ihrer Daten.
    </p>
  </div>
</template>

<script>
  export default {
    mounted(){
     
    }
  }
</script>

<style>
  #datenschutz {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 100px;
    background-color: #2c2c2c;
    text-align: left;
    color: white;
    margin-bottom: -20px;
    z-index: 2;
    position: relative;
  }
</style>