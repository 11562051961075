<template>
	<div id="kontakt">
		<b-modal ref="mail-send-modal" hide-footer hide-header>
			<div class="d-block text-center">
				<h4>{{ status }}</h4>
			</div>
			<b-spinner></b-spinner>
		</b-modal>
		<b-modal ref="mail-succ-modal" hide-footer hide-header>
			<div class="d-block text-center">
				<h4>{{ status }}</h4>
				<p>Wir bearbeiten Ihre Anfrage so schnell wie möglich!</p>
				<b-button class="mt-3" block @click="$refs['mail-succ-modal'].hide()">Okay!</b-button>
			</div>
		</b-modal>
		<b-modal ref="mail-err-modal" hide-footer hide-header>
			<div class="d-block text-center">
				<h4>{{ status }}</h4>
				<p>Es tut uns Leid!<br><a
						href="mailto:info@calvinherbconstruction.de?subject=Anfrage Kontaktformular">Sie können Ihre
						Anfrage per Mail an uns senden!</a></p>
				<b-button class="mt-3" block @click="$refs['mail-err-modal'].hide()">Okay!</b-button>
			</div>
		</b-modal>
		<h2 data-aos="fade-in" data-aos-duration="1200">
			Kontakt
		</h2>
		<hr class="heading-hr">
		<div id="kontakt-content" >
			<h3 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="500">
				Sie haben Fragen? Wir sind für Sie da!
			</h3>
			<div id="contact-form" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="800">
				<b-form @submit="onSubmit" @reset="onReset" v-if="show">
					<b-row>
						<b-col sm="4">
							<b-form-group id="input-group-2">
								<b-form-input id="input-2" v-model="form.name" placeholder="Name *" required>
								</b-form-input>
							</b-form-group>
							<b-form-group id="input-group-3">
								<b-form-input id="input-3" v-model="form.firma" placeholder="Firma">
								</b-form-input>
							</b-form-group>
							<b-form-group id="input-group-1">
								<b-form-input id="input-1" v-model="form.email" type="email"
									placeholder="E-Mail-Adresse *" required></b-form-input>
							</b-form-group>
							<b-form-group id="input-group-4">
								<b-form-input id="input-4" v-model="form.phone" placeholder="Telefonnummer">
								</b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="8">
							<b-form-textarea id="textarea-large" size="md" v-model="form.msg"
								placeholder="Ihre Nachricht *" required>
							</b-form-textarea>
							<b-form-group id="input-group-5" v-slot="{ ariaDescribedby }">
								<p>
									<router-link :to="'/datenschutz'">Datenschutzerklärung *</router-link>
								</p>
								<b-form-checkbox-group v-model="form.checked" id="checkboxes-4"
									:aria-describedby="ariaDescribedby" required>
									<b-form-checkbox value="datenschutz" required>Mit dem Absenden dieses Formulars
										bestätige
										ich, dass ich die Datenschutzerklärung gelesen habe und mit der Nutzung meiner
										personenbezogenen Daten nach Maßgabe dieser Datenschutzerklärung einverstanden
										bin.</b-form-checkbox>
								</b-form-checkbox-group>
							</b-form-group>

							<b-button type="submit" id="contact-btn">Anfrage senden</b-button>
						</b-col>
					</b-row>
				</b-form>
				<!-- <b-card class="mt-3" header="Form Data Result">
					<pre class="m-0">{{ form }}</pre>
				</b-card> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					email: '',
					name: '',
					firma: '',
					phone: '',
					msg: '',
					checked: []
				},
				show: true,
				status: ''
			}
		},
		mounted() {
		},
		methods: {

			onSubmit(event) {
				this.status = "Anfrage wird gesendet..."
				this.showModal("send")
				var ref = this
				event.preventDefault()
				var form = this.form
				var axios = require('axios');
				var data = JSON.stringify({
					"name": form.name,
					"firma": form.firma,
					"mail": form.email,
					"phone": form.phone,
					"message": form.msg
				});

				var config = {
					method: 'post',
					url: 'https://calvinherbconstruction.de/MailService/mailer.php',
					headers: {
						'Content-Type': 'application/json'
					},
					data: data
				};

				axios(config)
					.then(function (response) {
						if (response.data == "Message has been sent successfully") {
							ref.showModal("succ")
						} else {
							ref.showModal("err")
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			},
			onReset(event) {
				event.preventDefault()
				// Reset our form values
				// Trick to reset/clear native browser form validation state
				this.show = false
				this.$nextTick(() => {
					this.show = true
				})
			},
			showModal(status) {
				if (status == "send") {
					this.$refs['mail-send-modal'].show()
				}
				if (status == "succ") {
					this.status = "Anfrage erfolgreich versendet!"
					this.$refs['mail-send-modal'].hide()
					this.$refs['mail-succ-modal'].show()
				}
				if (status == "err") {
					this.status = "Ups, hier ist etwas schiefgelaufen!"
					this.$refs['mail-send-modal'].hide()
					this.$refs['mail-err-modal'].show()
				}
			},
			toggleModal() {
				// We pass the ID of the button that we want to return focus to
				// when the modal has hidden
				this.$refs['my-modal'].toggle('#toggle-btn')
			}
		}
	}
</script>

<style>
	#kontakt {
		padding-top: 100px;
		background-color: #D8D8D8;
		text-align: left;
		padding-bottom: 100px;
	}

	#kontakt h2 {
		color: black;
		text-align: left;
		font-family: 'MetropolisRegular';
		font-weight: bolder !important;
		font-size: 30px !important;
	}

	#kontakt-content,
	#kontakt h2 {
		padding-left: 10vw;
		padding-right: 10vw;
	}

	#kontakt h3 {
		color: black;
		text-align: left;
		font-family: 'MetropolisRegular';
		font-weight: bolder !important;
		font-size: 25px !important;
		margin-top: 50px;
	}

	#kontakt h4 {
		color: black;
		text-align: center;
		font-family: 'MetropolisRegular';
		font-weight: bolder !important;
		font-size: 25px !important;
		margin-top: 50px;
	}

	.spinner-border {
		margin: auto;
		display: block !important;
	}

	.modal-body,
	.modal-content {
		background-color: var(--black);
		color: white;
		border-radius: 20px !important;
	}


	#contact-form {
		margin-top: 50px;
	}

	#kontakt p {
		margin-top: 20px;
	}

	#kontakt input {
		border-radius: 20px !important;
		height: 50px;
		margin-bottom: 20px;
	}

	#kontakt textarea {
		border-radius: 20px !important;
		min-height: 10vw;
	}

	#kontakt .custom-control-input {
		margin-bottom: -10px;
		height: 10px;
		margin-right: 10px;
		color: white !important;
	}

	#kontakt .custom-control-label {
		display: contents;
	}

	input[type="checkbox"] {
		/* change "blue" browser chrome to yellow */
		filter: invert(100%) hue-rotate(18deg) brightness(1.7);
	}

	#contact-btn {
		text-transform: uppercase;
		background-color: var(--yellow);
		border: none;
		margin-top: 25px;
		color: black;
		background-color: var(--yellow);
		padding: 10px;
		border-radius: 10px;
		-webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.3);
		box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.3);
		transition: 0.5s;
	}

	#contact-btn:hover {
		background-color: black;
		color: white;
	}
</style>