<template>
  <div id="unternehmen">
    <h2 data-aos="fade-in" data-aos-duration="1200" data-aos-delay="00">
      Unternehmen
    </h2>
    <hr class="heading-hr" />
    <div id="unternehmen-content">
      <div class="row">
        <div class="col-sm-4">
          <img
            id="calvin-img"
            src="@/assets/Bilder/Calvin.jpg"
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-delay="00"
          />
          <div id="vl-calvin"></div>
          <div id="pt-calvin"></div>
        </div>
        <div
          class="col-8"
          data-aos="fade-in"
          data-aos-duration="800"
          data-aos-delay="1700"
        >
          <p>
            Das Unternehmen cHc - Calvin Herb Construction UG
            (Haftungsbeschränkt) wurde 2021 vom 24-jährigen Straßenbauermeister
            Calvin Herb gegründet und bedient seither Kunden im Enzkreis und
            Umgebung.
          </p>
          <p>
            cHc deckt den Bereich Straßenbauarbeiten in Form von Wegebau und
            Pflasterbau ab. Darüber hinaus spielt das Spektrum der Landschafts -
            und Gartengestaltung in allen Formen eine wichtige Rolle im
            Unternehmen.
          </p>
          <p>
            Komplexe Projektvorhaben Ihrer Kundenwünsche können wir dank einer
            fachgerechten Vorplanung unter Einhaltung aller gesetzlichen und
            rechtlichen Vorgaben effizient erfüllen.
          </p>
          <img
            v-rellax="rellax"
            data-rellax-speed="-0.5"
            id="unternehmen-img"
            src="@/assets/Bilder/unternehmen.jpg"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-delay="00"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    const startAnimation = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          "linear-wipe-down-Unternehmen",
          entry.isIntersecting
        );
      });
    };

    const observer = new IntersectionObserver(startAnimation);
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };

    const elements = document.querySelectorAll("#vl-calvin");
    elements.forEach((el) => {
      observer.observe(el, options);
    });
  },
};
</script>

<style>
#unternehmen {
  padding-top: 100px;
  background-color: #d8d8d8;
  text-align: left;
  padding-bottom: 100px;
  z-index: 1;
  position: relative;
}

#unternehmen h2 {
  color: black;
  text-align: left;
  font-family: "MetropolisRegular";
  font-weight: bolder !important;
  font-size: 30px !important;
}

#unternehmen-content,
#unternehmen h2 {
  padding-left: 10vw;
  padding-right: 10vw;
}

#calvin-img {
  border-radius: 20px;
  margin-top: 50px !important;
  border: 5px solid black;
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  width: 100%;
}

#vl-calvin {
  border-left: 5px solid black;
  height: 0vw;
  border-radius: 0px 0px 7px 7px;
  width: 5px;
  position: relative;
  left: 50%;
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
}

.linear-wipe-down-Unternehmen {
  white-space: nowrap;
  overflow: hidden;
  animation: linearwipedownUnternehmen 0.75s steps(120, end);
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
}

#pt-calvin {
  height: 20px;
  width: 20px;
  position: relative;
  left: 50%;
  margin-left: -7.75px;
  margin-top: -10px;
  background-color: black;
  border-radius: 50%;
}

@keyframes linearwipedownUnternehmen {
  from {
    height: 0;
  }

  100% {
    height: 100px;
  }
}

#unternehmen-content p {
  color: black;
  text-align: left;
  font-size: 20px;
  font-weight: 400 !important;
  margin-top: 30px;
}

#unternehmen-content .col-8 {
  margin-top: 15px;
  padding-left: 50px;
}

#unternehmen-img {
  width: 50%;
  float: right;
  border-radius: 20px;
  margin-top: 10px;
  border: 5px solid black;
  -webkit-box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.23);
}

@media only screen and (max-width: 900px) {
  #calvin-img {
    width: 300px;
  }

  #vl-calvin {
    left: 150px;
  }

  #pt-calvin {
    left: 150px;
  }

  #unternehmen-content .col-8 {
    margin-top: 20px;
    padding-left: 145px;
  }
  #unternehmen-img {
    display: none;
  }
}

@media only screen and (max-width: 599px) {
  #calvin-img {
    width: 100%;
  }
  #vl-calvin {
    left: 50%;
  }

  #pt-calvin {
    left: 50%;
  }
  #unternehmen-content .col-8 {
    margin-top: 20px;
    padding-left: 5vw;
    width: 100vw;
  }
  #unternehmen-content p {
    width: 75vw;
  }
}
</style>
