<template>
  <div id="impressum">
    <div id="impressumContainer">
      <h1>Impressum</h1>
      <p>Calvin Herb Construction UG (Haftungsbeschränkt)</p>
      <p>Schubertstraße 13 75334 Straubenhardt</p>
      <p>
        Telefon: 01749739353<br />
        E-Mail:
        <a href="mailto:info@calvinherbconstruction.de"
          >info@calvinherbconstruction.de</a
        ><br />
        Internet:
        <a href="https://calvinherbconstruction.de/" target="_blank"
          >www.calvinherbconstruction.de</a
        >
      </p>
      <p>
        Inhaber: Calvin Herb<br />
        Geschäftsleitung: Calvin Herb
        <br />
        Handelsregisternummer: Amtsgericht Mannheim HRB746710
        <br />
        Umsatz-Steuer-ID:
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style>
#impressum {
  margin-left: 10vw;
  margin-right: 10vw;
  position: relative;
  height: 70vh;
}

#impressumContainer {
  background-color: rgba(112, 112, 112, 0.5);
  border-radius: 25px;
  text-align: left;
  color: white;
  margin-bottom: 100px;
  padding-bottom: 20px;
  margin-top: 100px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
}

#impressum a {
  color: var(--yellow);
}
</style>
